// noinspection JSUnusedLocalSymbols

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER, baseUrl} from "../../../constants/common";
import {useUiContext} from "../../../contexts/UiContext";
import HeaderDashboard from "../../common/HeaderDashboard";
import RecordingSidebar from "../../common/RecordingSidebar";
import Svg from "../../common/Svg";
import {checkUserRoutePermission, getLocal} from "../../../helper/common";
import Timeline from "./Timeline";
import moment from "moment-timezone";

export default function Index({route, handlesubmit}) {
  let role = getLocal("role");
  const {noOfColumns, apiRequest, syncAuthToken, getAuthToken, searchFilter} = useUiContext();
  const [recordingList, setRecordingList] = useState([]);
  const [timelineRecordings, setTimelineRecordings] = useState([]);
  const [timelineFullRecordings, setTimelineFullRecordings] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPageNo, setTotalPageNo] = useState(1);
  const noForBootstrapClass = 12 / noOfColumns;
  // eslint-disable-next-line no-unused-vars
  const [groupUuid, setGroupUuid] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [enable, setEnable] = useState(true);
  const [deviceUuid, setDeviceUuid] = useState("");
  const timeZone = localStorage.getItem("tz");
  const [timeQuarterlyBlock, setTimeQuarterlyBlock] = useState([]);

  const [filter, setFilter] = useState({
    start_time: "",
    device_uuid: [],
    group_uuid: "",
    startDatetime: moment().tz(timeZone).startOf("day").toISOString(true),
    endDatetime: moment().tz(timeZone).add(1, "day").startOf("day").toISOString(true),
  });

  
  const [timelineParams, setTimelineParams] = useState({
    start_time: "",
    device_uuid: [],
    group_uuid: "",
    startDatetime: moment().tz(timeZone).startOf("day").toISOString(true),
    endDatetime: moment().tz(timeZone).add(1, "day").startOf("day").toISOString(true),
  });
  const [pageValue, setPageValue] = useState("");
  const [selectedTimeframe, setSelectedTimeframe] = useState("");


    
    
    useEffect(() => {      
  let data = localStorage.getItem('filter');
  data = JSON.parse(data);   
      if (data && data.device_uuid) {
        setFilter({...filter,device_uuid:data.device_uuid})
      }
    }, []);  

  const getRecordingsApiRequest = useCallback((apiPath, page, pageSize = 20, showLoader = true) => {
    
  let data = localStorage.getItem('filter');
  data = JSON.parse(data);
    let params = {
      page: page ? page : 1,
      start_time_after: filter.startDatetime,
      start_time_before: filter.endDatetime,
      ordering: sortBy,
      page_size: pageSize,
    };
    if (filter.group_uuid) params.group_uuid = filter.group_uuid;
    if (searchFilter) params.search = searchFilter;
    if (filter.device_uuid && data?.device_uuid) params.device_uuid__in = data?.device_uuid.join(",");

    return apiRequest("get", `api/1/${apiPath}/`, "", null, showLoader, {success: '', error: ''}, params);
  }, [filter.device_uuid, filter.endDatetime, filter.group_uuid, filter.startDatetime, searchFilter, sortBy]);

  const getRecordingList = useCallback(async () => {
    if (!pageNo) setRecordingsPage(1);
    return await getRecordingsApiRequest("recordings", pageNo).then(res => {
      if (res.data?.page) {
        // noinspection JSUnresolvedReference
        setTotalPageNo(res.data.page_count);
        setRecordingList(res.data.results);
      } else {
        setRecordingList([]);
        console.warn(res.data);
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [filter.device_uuid, filter.endDatetime, filter.group_uuid, filter.startDatetime, pageNo, searchFilter, sortBy]);

  const getTimelineRecordingsPage = useCallback(async (page, previousResults) => {
    return getRecordingsApiRequest("timeline-recordings", page, 100, false).then(response => {
      const page = response.data?.page;
      const pageCount = response.data?.page_count;
      if (page && pageCount) {
        const newResults = [...previousResults, ...response.data.results];
        setTimelineRecordings(newResults);
        if (page < pageCount) return getTimelineRecordingsPage(page + 1, newResults);
        return newResults;
      } else {
        console.warn(response.data);
      }
      return [];
    }).catch((error) => {
      console.error(error);
    });
  }, [filter.device_uuid, filter.endDatetime, filter.group_uuid, filter.startDatetime, searchFilter, sortBy]);

  const getTimelineRecordingsAllData = useCallback(async (page, previousResults) => {
    return getRecordingsApiRequest("timeline-recordings", page, 100, false).then(response => {
      const page = response.data?.page;
      const pageCount = response.data?.page_count;
      if (page && pageCount) {
        const newResults = [...previousResults, ...response.data.results];
        setTimelineFullRecordings(newResults);
        if (page < pageCount) return getTimelineRecordingsAllData(page + 1, newResults);
        return newResults;
      } else {
        console.warn(response.data);
      }
      return [];
    }).catch((error) => {
      console.error(error);
    });
  }, [timelineParams.device_uuid, timelineParams.endDatetime, timelineParams.group_uuid, timelineParams.startDatetime, searchFilter, sortBy]);

  const getTimelineRecordings = async () => {
    return getTimelineRecordingsPage(1, []);
  };

  const downloadVideo = (recordingUuid) => {
    return getAuthToken().then(token => {
      const link = `${baseUrl}api/1/recordings/${recordingUuid}/video/?as_attachment=1&authtoken=${token}`;
      return window.open(link, "_blank");
    });
  };

  const snapshotImgSrc = (recordingUuid) => {
    // Note: we can use this because this should already be inside the context of getAuthToken()...getRecordingList()
    const token = syncAuthToken();
    return `${baseUrl}api/1/recordings/${recordingUuid}/snapshot_img/?as_attachment=1&authtoken=${token}`;
  };

  useEffect(() => {
    getAuthToken().then(_ => {
      getRecordingList().then(_ => {
      });
    });
  }, [filter, filter.endDatetime, filter.group_uuid, filter.startDatetime, pageNo, searchFilter, sortBy]);

  useEffect(() => {
    getTimelineRecordings().then(_ => {
    });
  }, [filter, filter.endDatetime, filter.group_uuid, filter.startDatetime, searchFilter, sortBy]);

  useEffect(() => {
    getTimelineRecordingsAllData(1, []).then(_ => {
    });
  }, [timelineParams.device_uuid, timelineParams.endDatetime, timelineParams.group_uuid, filter.startDatetime, searchFilter, sortBy]);

  const isAdmin = localStorage.getItem("isadmin") === "true";

  function handlePageChange(page) {
    if (setRecordingsPage(page)) {
      resetTimelineSelection();
      setSelectedTimeframe('');
    }
  }

  function setRecordingsPage(page) {
    let pageInt ;
    let pageStr ;
    if (!page) {
      pageInt = pageNo;
      if (pageInt < 1) pageInt = 1;
      if (pageInt > totalPageNo) pageInt = totalPageNo;
      pageStr = "";
    } else if (Number.isInteger(page)) {
      pageInt = page;
      if (pageInt < 1) pageInt = 1;
      if (pageInt > totalPageNo) pageInt = totalPageNo;
      pageStr = `${pageInt}`;
    } else {
      pageInt = parseInt(page, 10);
      if (isNaN(pageInt)) {
        pageInt = 1;
        pageStr = "";
      } else {
        if (pageInt < 1) pageInt = 1;
        if (pageInt > totalPageNo) pageInt = totalPageNo;
        pageStr = `${pageInt}`;
      }
    }
    const changePageStr = (pageValue !== pageStr);
    const changePageNo = (pageNo !== pageInt);
    if (changePageStr) setPageValue(pageStr);
    if (changePageNo) setPageNo(pageInt);
    return changePageNo || changePageStr;
  }

  function handlePageValueChange(event) {
    const value = event.target.value;
    setPageValue(value);
  }
  function handlePageValueKeyDown(event) {
    handlePageValueChange(event);
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        handlePageChange(pageValue);
        break;
    }
  }
  function handlePageValueFocusout(event) {
    handlePageValueChange(event);
    handlePageChange(pageValue);
  }

  function handleTimeframeSelection(hour, minute, index) {
    let page = 0;
    let sum = index;
    let i = 0;

    while (sum >= 0) {
      i++;
      sum = sum - 20;
      if (sum < 0) {
        page = i;
      }
      if (sum === 0) {
        page = i + 1;
      }
    }

    setRecordingsPage(page);

    setSelectedTimeframe(moment.tz({hour, minute}, timeZone).format());
    const timeline_selector = document.getElementById(`timeframe-${hour}-${minute}`);

    const day_hours_selector = document.getElementById('day_hours');

    [...day_hours_selector.childNodes]?.map(hourChild => {
      return [...hourChild.childNodes]?.map(minuteChild => {
        if (minuteChild.classList.contains('selected')) {
          minuteChild.classList.remove('selected');
        } else {
          timeline_selector.classList.add('selected');
        }
        return minuteChild;
      });
    });
  }

  function resetTimelineSelection() {
    // setTimelineRecordings([]);
    const day_hours_selector = document.getElementById('day_hours');

    [...day_hours_selector.childNodes]?.map(hourChild => {
      return [...hourChild.childNodes]?.map(minuteChild => {
        if (minuteChild.classList.contains('selected')) {
          minuteChild.classList.remove('selected');
        }
        return minuteChild;
      });
    });
  }

  const filteredRecordings = useMemo(() => {
    
    if (selectedTimeframe) {
      const selectedHour = moment.tz(selectedTimeframe, timeZone).format("h a");
      return recordingList.filter(recording => moment.tz(recording.start_time, timeZone).format('h a') === selectedHour);
    } else {
      return recordingList;
    }
  }, [recordingList, selectedTimeframe, timeZone]);


  /* jshint ignore:start*/
  return (
      <div className="d-flex flex-column site-main-wrapper grid-page">
        <HeaderDashboard route={route}/>
        <div className="flex-grow-1 main-wrapper">
          {/* listing page */}

          <div className="main-page-height devices-page d-flex">
            <RecordingSidebar
                deviceUuid={deviceUuid}
                setGroupUuid={setGroupUuid}
                setDeviceUuid={setDeviceUuid}
                setSortBy={setSortBy}
                setFilter={setFilter}
                filter={filter}
                setFilterByDate={getRecordingList}
                enable={enable}
                setEnable={setEnable}
                route={route}
                setSelectedTimeframe={setSelectedTimeframe}
                resetTimelineSelection={resetTimelineSelection}
                timeQuarterlyBlock={timeQuarterlyBlock}
                setTimeQuarterlyBlock={setTimeQuarterlyBlock}
            />
            <div className="flex-grow-1 recording-wrapper">
              <div className="d-flex flex-column">
                <div className="camera-header d-flex align-items-center">
                  <div className="flex-grow-1 available-heading">
                    <h2>Available Recordings</h2>
                  </div>
                  <div className="d-lg-none">
                    <button
                        className="btn btn-sidebar-toggle p-0"
                        type="button"
                        onClick={() => setEnable(false)}
                    >
                      <Svg name="filter-icon"/>
                    </button>
                  </div>
                  <div className="dropdown d-none d-lg-flex">
                    <button
                        className="btn"
                        type="button"
                        id="dropdownSorting"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                      <Svg name="dropdown-icon"/>
                    </button>
                    <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownSorting"
                    >
                      <li>
                      <span
                          className="dropdown-item"
                          onClick={() => setSortBy("start_time")}
                      >
                        By Date
                      </span>
                      </li>
                      <li>
                      <span
                          className="dropdown-item"
                          onClick={() => setSortBy("device__name")}
                      >
                        By Name
                      </span>
                      </li>
                      {/* <li><span className="dropdown-item" onClick={()=>setSortBy("device__name")}>A To Z</span></li>
                                        <li><span className="dropdown-item" onClick={()=>setSortBy("-device__name")}>Z to A</span></li> */}
                    </ul>
                  </div>
                </div>
                <div className="flex-grow-1 camera-list-wrapper">
                  {/* Recording motion activity Timeline */}
                  <Timeline
                      setTimeQuarterlyBlock={setTimeQuarterlyBlock}
                      recordings={timelineRecordings}
                      timelineFullRecordings={timelineFullRecordings}
                      filter={filter}
                      setFilter={setFilter}
                      setTimelineParams={setTimelineParams}
                      timelineParams={timelineParams}
                      handleTimeframeSelection={handleTimeframeSelection}
                      resetTimelineSelection={resetTimelineSelection}
                      setSelectedTimeframe={setSelectedTimeframe}
                      setPageNo={setPageNo}
                      timeQuarterlyBlock={timeQuarterlyBlock}
                  />

                  {/*If Grid is Camera - 4x3*/}

                  <div className="camera-row row four-by-three">
                    {filteredRecordings?.map((data, index) => (
                        <div
                            className={`col-lg-${noForBootstrapClass} mb-4`}
                            key={index}
                        >
                          <div className="card-camera">
                            <div className="card-header d-flex">
                              <div className="camera-status">
                                <Svg name="camera-active"/>
                              </div>
                              <div className="camera-details flex-grow-1 d-flex align-items-center">
                                <div className="flex-grow-1 camera-name">
                                  {data.device.name}
                                </div>
                                <div className="dropdown notification-dropown ">
                                  <button
                                      className="btn-notif"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                  >
                                    <Svg name="notification"/>
                                  </button>
                                  <div
                                      className="dropdown-menu dropdown-menu-end"
                                      aria-labelledby="dropdownMenuButton1"
                                  >
                                    <div className="notif-header">
                                      <a href="#">Mark all as read</a>
                                    </div>
                                    <ul>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                      <span className="me-2">
                                        {moment.tz(data.start_time, timeZone).format("DD/MM/YYYY HH:mm a")}
                                      </span>
                                          <span>{data.event_id}</span>
                                        </a>
                                      </li>
                                    </ul>
                                    {checkUserRoutePermission(
                                        role,
                                        `/${route}/cameras/notifications/:device_uuid`
                                    ) && (
                                        <div className="notification-footer">
                                          <Link
                                              to={`/${route}/cameras/notifications/${data.recording_uuid}`}
                                          >
                                            <button className="btn btn-primary btn-rounded">
                                              View All
                                            </button>
                                          </Link>
                                        </div>
                                    )}
                                  </div>
                                </div>
                                {isAdmin ? checkUserRoutePermission(
                                    role,
                                    `/${route}/cameras/details/:device_uuid`
                                ) && (
                                    <Link
                                        to={`/${route}/cameras/details/${data.device.device_uuid}`}
                                        className="btn-notif ms-1 details-btn"
                                    >
                                      <Svg name="setting"/>
                                    </Link>
                                ) : null}
                              </div>
                            </div>
                            <div className="card-subhead">
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1 text-wrapper">
                                  <div className="d-flex title">
                                    <div className="icon">
                                      <Svg name="play-icon"/>
                                    </div>
                                    <p className="m-0">{data.event_id}</p>
                                  </div>
                                  <div className="d-flex title">
                                    <div className="icon">
                                      <Svg name="time-icon"/>
                                    </div>
                                    <p className="m-0">
                                      {moment.tz(data.start_time, timeZone).format("DD/MM/YYYY HH:mm:ss")} <br/>
                                    </p>
                                  </div>
                                </div>
                                { [ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, 
                                    MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, 
                                      SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER].includes(getLocal('userRole')) && 
                                      (data.storage_recording_file_exists ? (
                                          <div className="icon-wrap"
                                                  style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                    downloadVideo(data.recording_uuid)
                                                  }}
                                          >
                                            <Svg name="download-icon"/>
                                          </div>
                                      ) : (
                                          <div className="icon-wrap"
                                          >
                                            <Svg name="download-icon-disabled"/>
                                          </div>
                                      ))                               
                                }
                              </div>
                            </div>
                            {checkUserRoutePermission(
                                role,
                                `/${route}/recordings/camera-view/:recording_uuid`
                            ) && data.snapshot_file_exists ? (
                                <Link
                                    to={`/${route}/recordings/camera-view/${data.recording_uuid}`}
                                >
                                  <div className="card-camera-body">
                                    <img
                                        src={snapshotImgSrc(data.recording_uuid)}
                                        alt=""
                                        className="img-fluid"
                                    />
                                  </div>
                                </Link>
                            ) : (
                                <Link
                                    to={`/${route}/recordings/camera-view/${data.recording_uuid}`}
                                >
                                  <div className="card-camera-body no-subscriptions novideo">
                                    <div className="alert alert-primary">
                                      {data.snapshot_description}
                                    </div>
                                  </div>
                                </Link>
                            )}
                          </div>
                        </div>
                    ))}
                  </div>
                  <div className="d-md-flex flex-wrap justify-content-center page-nav">
                    {!recordingList.length > 0 ||
                        (pageNo > 1 && (
                            <div className="text-center pt-2 mx-2 ">
                              <Link
                                  to={""}
                                  className="btn btn-primary btn-rounded"
                                  onClick={() => handlePageChange(pageNo - 1)}
                              >
                                Previous Page
                              </Link>
                            </div>
                        ))}
                    {!recordingList.length > 0 ||
                        (pageNo < totalPageNo && (
                            <div className="text-center pt-2 mx-2 ">
                              <Link
                                  to={""}
                                  className="btn btn-primary btn-rounded"
                                  onClick={() => handlePageChange(pageNo + 1)}
                              >
                                Next Page
                              </Link>
                            </div>
                        ))}
                    {!recordingList.length > 0 ||
                        (pageNo <= totalPageNo && (
                            <div className="pagination-btn text-center pt-2 mx-2">
                              <p className="no-underline heading">
                                Page {pageNo} of {totalPageNo}
                              </p>
                            </div>
                        ))}
                    {!recordingList.length > 0 ||
                        (pageNo <= totalPageNo && (
                            <div className="d-flex pt-2 mx-2">
                              <p className="pt-3 mx-3 no-underline heading">
                                Go to Page#
                              </p>
                              <input
                                  className="page-search form-control mr-sm-2"
                                  type="search"
                                  id="search"
                                  placeholder="Search"
                                  value={pageValue}
                                  onChange={handlePageValueChange}
                                  onKeyDown={handlePageValueKeyDown}
                                  onBlur={handlePageValueFocusout}
                                  aria-label="Search"
                              />
                              <button
                                  className="search-btn btn btn-primary btn-rounded my-2 my-sm-0"
                                  onClick={() => {
                                    handlePageChange(pageValue);
                                  }}
                              >
                                Go
                              </button>
                            </div>
                        ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
  /* jshint ignore:end */
}
