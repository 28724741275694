import React from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import ResetPassword from "./views/authentication/forgot-password/ResetPassword";
import SuccessMessage from "./views/authentication/forgot-password/Success";
import VerifyCode from "./views/authentication/forgot-password/VerifyCode";
import VerifyEmail from "./views/authentication/forgot-password/VerifyEmail";
import Login from "./views/authentication/Login";
import Signup from "./views/authentication/Signup";
import HelpCenter from "./views/content-pages/HelpCenter";
import ReportProblem from "./views/content-pages/ReportProblem";
import AddNewDevice from "./views/customer/cameras/AddNewDevice";
import Cameras from "./views/customer/cameras/Index";
import AccountDetails from "./views/customer/settings/AccountDetails";
import DevicesStorage from "./views/customer/settings/DevicesStorage";
import ProfileSettings from "./views/customer/settings/Index";
import UsersPermissions from "./views/customer/settings/users-permissions/UsersPermissions";
import Adduser from "./views/customer/settings/users-permissions/AddUser";
import ResellersSystemIntegrators from "./views/customer/settings/ResellersSystemIntegrators";
import ReportsAnalytics from "./views/customer/settings/ReportsAnalytics";
import BillableSubscriptions from "./views/customer/settings/BillableSubscriptions";
import Recordings from "./views/customer/recordings/Index";
import CameraView from "./views/customer/recordings/CameraView";
import Details from "./views/customer/recordings/Details";
import LiveView from "./views/customer/recordings/LiveView";
import Notifications from "./views/customer/recordings/Notifications";
// import Notes from "./views/customer/notes/Index"; // This feature does not exist
import PrivacyPolicy from "./views/content-pages/PrivacyPolicy";
import Terms from "./views/content-pages/Terms";
import Groups from "./views/customer/groups/Index";
import GroupDetails from "./views/customer/groups/Details";
import SignupAddOtp from "./views/authentication/SignupAddOtp";
import SignupComplete from "./views/authentication/SignupComplete";

import ProtectedRoute from './component/PrivateRoutes';
import Redirect from './component/Redirect';
import Loader from './component/loader';
import AddressList from "./views/customer/settings/user-address/AddressList";
import AddAddress from "./views/customer/settings/user-address/AddAddress";
import EditAddress from "./views/customer/settings/user-address/EditAddress";

import ResellerDetails from "./views/reseller/Details";
import BillableSubscriptionsSummary from "./views/reseller/BillableSubscriptionsSummary";
import UnassignedSubscriptionsSummary from "./views/reseller/unassigned-subscriptions-summary/UnassignedSubscriptionsSummary";
import AssignSubscription from "./views/reseller/unassigned-subscriptions-summary/AssignSubscription";
import DevicesStorageSummary from "./views/reseller/DevicesStorageSummary";
import UsersSummary from "./views/reseller/users-summary/UsersSummary";
import AddSummaryUser from "./views/reseller/users-summary/AddUser";
import DistributorsCustomers from "./views/reseller/DistributorsCustomers";
import SubscriptionReports from "./views/reseller/SubscriptionReports";
import EndSubscriptions from "./views/customer/settings/EndSubscriptions";
import ChangePassword from "./views/customer/settings/ChangePassword";
// import EndSubscription from "./views/reseller/EndSubscription";
import CreateSubscription from "./views/reseller/unassigned-subscriptions-summary/CreateSubscription";
import HeaderMetaData from "./views/common/HeaderMetaData";
import CreateReseller from "./views/customer/settings/CreateReseller";
import AddSiteMap from "./views/customer/settings/user-address/AddSiteMap";
import Mapview from "./views/customer/siteview/MapView";
import UserListTable from "./views/customer/settings/UserListTable";
import SitesList from "./views/customer/settings/user-address/SitesList";
import EditSite from "./views/customer/settings/user-address/EditSite";
import EditUser from "./views/customer/settings/users-permissions/EditUser";
import CreateResellerCustomer from "./views/customer/settings/CreateResellerCustomer";
import 'react-tooltip/dist/react-tooltip.css'

function App() {
  return (
    <>
      <Loader />
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <HeaderMetaData />
      <Routes>
        <Route exact path="/" element={<Redirect Component={Login} />}> </Route>
        <Route exact path="/login" element={<Redirect Component={Login} />}> </Route>
        <Route exact path="/sign-up" element={<Redirect Component={Signup} />}> </Route>
        <Route exact path="/sign-up-verify" element={<Redirect Component={SignupAddOtp} />}> </Route>
        <Route exact path="/sign-up-complete" element={<Redirect Component={SignupComplete} />}> </Route>
        <Route exact path="/verify-email" element={<Redirect Component={VerifyEmail} />}> </Route>
        <Route exact path="/verify-code" element={<Redirect Component={VerifyCode} />}> </Route>
        <Route exact path="/reset-password" element={<Redirect Component={ResetPassword} />}> </Route>
        <Route exact path="/success-message" element={<SuccessMessage />}> </Route>

        <Route exact path="/customer/cameras" element={<ProtectedRoute Component={Cameras} />}> </Route>
        <Route exact path="/customer/cameras/add-new-device" element={<ProtectedRoute Component={AddNewDevice} />}> </Route>
        <Route exact path="/customer/cameras/live-camera/:device_uuid" element={<ProtectedRoute Component={LiveView} />}> </Route>
        <Route exact path="/customer/cameras/details/:device_uuid" element={<ProtectedRoute Component={Details} />}> </Route>
        <Route exact path="/customer/cameras/notifications/:device_uuid" element={<ProtectedRoute Component={Notifications} />}> </Route>

        <Route exact path="/customer/groups" element={<ProtectedRoute Component={Groups} />}> </Route>
        <Route exact path="/customer/group/details/:group_uuid" element={<ProtectedRoute Component={GroupDetails} />}> </Route>


        <Route exact path="/customer/help-center" element={<ProtectedRoute Component={HelpCenter} />}> </Route>
        <Route exact path="/customer/report-problem" element={<ProtectedRoute Component={ReportProblem} />}> </Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}> </Route>
        <Route exact path="/terms" element={<Terms />}> </Route>

        <Route exact path="/customer/profile-settings" element={<ProtectedRoute Component={ProfileSettings} />}> </Route>
        <Route exact path="/customer/change-password" element={<ProtectedRoute Component={ChangePassword} />}> </Route>
        <Route exact path="/customer/account-details" element={<ProtectedRoute Component={AccountDetails} />}> </Route>
        <Route exact path="/customer/sites" element={<ProtectedRoute Component={AddressList} />}> </Route>
        <Route exact path="/customer/sites/create" element={<ProtectedRoute Component={AddAddress} />}> </Route>
        <Route exact path="/customer/sites/edit" element={<ProtectedRoute Component={EditAddress} />}> </Route>
        <Route exact path="/customer/sites/:address_uuid/site" element={<ProtectedRoute Component={SitesList} />}> </Route>
        <Route exact path="/customer/sites/:address_uuid/site/:site_uuid" element={<ProtectedRoute Component={EditSite} />}> </Route>


        <Route exact path="/customer/sites/add-site-map" element={<ProtectedRoute Component={AddSiteMap} />}> </Route>
        <Route exact path="/customer/cameras/sites/map-view/:address_uuid" element={<ProtectedRoute Component={Mapview} />}> </Route>

        <Route exact path="/customer/billable-subscriptions" element={<ProtectedRoute Component={BillableSubscriptions} />}> </Route>

        <Route exact path="/customer/end-subscriptions" element={<ProtectedRoute Component={EndSubscriptions} />}> </Route>

        <Route exact path="/customer/devices-and-storage" element={<ProtectedRoute Component={DevicesStorage} />}> </Route>
        <Route exact path="/customer/users-and-permissions" element={<ProtectedRoute Component={UsersPermissions} />}> </Route>
        <Route exact path="/customer/users-and-permissions/create" element={<ProtectedRoute Component={Adduser} />}> </Route>
        <Route exact path="/customer/users-and-permissions/edit/:username" element={<ProtectedRoute Component={EditUser} />}> </Route>
        <Route exact path="/customer/resellers-system-integrators" element={<ProtectedRoute Component={ResellersSystemIntegrators} />}> </Route>
        <Route exact path="/customer/reports-and-analytics" element={<ProtectedRoute Component={ReportsAnalytics} />}> </Route>

        <Route exact path="/customer/recordings" element={< ProtectedRoute Component={Recordings} />}> </Route>
        <Route exact path="/customer/recordings/camera-view/:recording_uuid" element={<ProtectedRoute Component={CameraView} />}> </Route>

        {/*<Route exact path="/customer/recordings/notes" element={<ProtectedRoute Component={Notes} />}> </Route> */}



        <Route exact path="/reseller/account-details" element={<ProtectedRoute Component={AccountDetails} />}> </Route>
        <Route exact path="/reseller/billable-subscriptions" element={<ProtectedRoute Component={BillableSubscriptions} />}> </Route>
        <Route exact path="/reseller/end-subscriptions" element={<ProtectedRoute Component={EndSubscriptions} />}> </Route>
        <Route exact path="/reseller/unassigned-subscriptions-summary" element={<ProtectedRoute Component={UnassignedSubscriptionsSummary} />}> </Route>
        <Route exact path="/reseller/unassigned-subscriptions-summary/assign" element={<ProtectedRoute Component={AssignSubscription} />}> </Route>
        <Route exact path="/reseller/unassigned-subscriptions-summary/create" element={<ProtectedRoute Component={CreateSubscription} />}> </Route>
        <Route exact path="/reseller/users-and-permissions" element={<ProtectedRoute Component={UsersPermissions} />}> </Route>
        <Route exact path="/reseller/users-and-permissions/create" element={<ProtectedRoute Component={Adduser} />}> </Route>
        <Route exact path="/reseller/users-and-permissions/edit/:username" element={<ProtectedRoute Component={EditUser} />}> </Route>
        <Route exact path="/reseller/devices-and-storage" element={<ProtectedRoute Component={DevicesStorage} />}> </Route>
        <Route exact path="/reseller/resellers-system-integrators" element={<ProtectedRoute Component={ResellersSystemIntegrators} />}> </Route>
        <Route exact path="/reseller/reports-and-analytics" element={<ProtectedRoute Component={ReportsAnalytics} />}> </Route>
        <Route exact path="/reseller/create-reseller" element={<ProtectedRoute Component={CreateResellerCustomer} />}> </Route>

        

        <Route exact path="/reseller/user-list-table" element={<ProtectedRoute Component={UserListTable} />}> </Route>





        <Route exact path="/distributor/account-details" element={<ProtectedRoute Component={AccountDetails} />}> </Route>
        <Route exact path="/distributor/billable-subscriptions" element={<ProtectedRoute Component={BillableSubscriptions} />}> </Route>
        <Route exact path="/distributor/end-subscriptions" element={<ProtectedRoute Component={EndSubscriptions} />}> </Route>
        <Route exact path="/distributor/unassigned-subscriptions-summary" element={<ProtectedRoute Component={UnassignedSubscriptionsSummary} />}> </Route>
        <Route exact path="/distributor/unassigned-subscriptions-summary/assign" element={<ProtectedRoute Component={AssignSubscription} />}> </Route>
        <Route exact path="/distributor/unassigned-subscriptions-summary/create" element={<ProtectedRoute Component={CreateSubscription} />}> </Route>
        <Route exact path="/distributor/users-and-permissions" element={<ProtectedRoute Component={UsersPermissions} />}> </Route>
        <Route exact path="/distributor/users-and-permissions/create" element={<ProtectedRoute Component={Adduser} />}> </Route>
        <Route exact path="/distributor/users-and-permissions/edit/:username" element={<ProtectedRoute Component={EditUser} />}> </Route>
        <Route exact path="/distributor/devices-and-storage" element={<ProtectedRoute Component={DevicesStorage} />}> </Route>
        <Route exact path="/distributor/resellers-system-integrators" element={<ProtectedRoute Component={ResellersSystemIntegrators} />}> </Route>
        <Route exact path="/distributor/reports-and-analytics" element={<ProtectedRoute Component={ReportsAnalytics} />}> </Route>
        <Route exact path="/distributor/create-reseller" element={<ProtectedRoute Component={CreateReseller} />}> </Route>
        {/* <Route exact path="/distributor/edit-reseller/:seller_id" element={<ProtectedRoute Component={CreateReseller} />}> </Route> */}



        <Route exact path="/reseller/details/old" element={<ResellerDetails />}> </Route>
        <Route exact path="/reseller/billable-subscriptions-summary/old" element={<BillableSubscriptionsSummary />}> </Route>

        <Route exact path="/reseller/devices-storage-summary/old" element={<DevicesStorageSummary />}> </Route>

        <Route exact path="/reseller/user-summary/old" element={<UsersSummary />}> </Route>
        <Route exact path="/reseller/user-summary/create-user/old" element={<AddSummaryUser />}> </Route>
        <Route exact path="/reseller/distributor/old" element={<DistributorsCustomers />}> </Route>
        <Route exact path="/reseller/subscription-reports/old" element={<SubscriptionReports />}> </Route>


      </Routes>

    </>
  );
}

export default App;
