import React, { useEffect, useMemo, useState } from "react";
import moment from "moment-timezone";
import { useUiContext } from "../../../contexts/UiContext";

export default function Timeline({
  recordings,
  filter,
  setFilter,
  handleTimeframeSelection,
  resetTimelineSelection,
  timelineFullRecordings,
  timelineParams,
  setTimelineParams,
  setSelectedTimeframe,
  setPageNo,
  setTimeQuarterlyBlock,
  timeQuarterlyBlock
}) {
  const timeZone = localStorage.getItem("tz");
  const [timelineDate, setTimelineDate] = useState(
    moment().tz(timeZone).toISOString(true)
  );

  const { apiRequest } = useUiContext();

  const [timeRangeArray, setTimeRangeArray] = useState([]);
  const [timeBlock, setTimeBlock] = useState([]);
  // const [timeQuarterlyBlock, setTimeQuarterlyBlock] = useState([]);
  const [show, setshow] = useState(false);

  function handleDateChange(type) {
    setTimeRangeArray([]);
    // resetTimelineSelection();
    setSelectedTimeframe("");
    setPageNo(1);
    const timelineMoment = moment.tz(timelineDate, timeZone).startOf("day");
    const startMoment =
      type === "next"
        ? timelineMoment.add(1, "day")
        : type === "prev"
        ? timelineMoment.subtract(1, "day")
        : timelineMoment;
    const startDatetime = startMoment.toISOString(true);
    const endDatetime = startMoment.add(1, "day").toISOString(true);
    setTimelineParams({
      ...timelineParams,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    });
    setFilter({
      ...filter,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    });
  }

  useEffect(() => {
    if (filter.device_uuid.length > 0) {
      getTimeBlock();
    }if(filter.device_uuid.length===0){
      getTimeBlock();
    }
  }, [filter]);


  let data = localStorage.getItem('filter');
    data = JSON.parse(data);

  function handleTimeChange(time) {
    const [startTime, endTime] = time.split("-").map(String);
    const [startHour, startMinute] = startTime.split(":").map(String);
    const [endHour, endMinute] = endTime.split(":").map(String);

    const starttimelineMoment = moment
      .tz(timelineDate, timeZone)
      .startOf("day");
    const endtimelineMoment = moment.tz(timelineDate, timeZone).startOf("day");

    const startDatetime = starttimelineMoment
      .add(startHour, "hour")
      .add(startMinute, "minute")
      .toISOString(true);
    const endDatetime = endtimelineMoment
      .add(endHour, "hour")
      .add(endMinute, "minute")
      .toISOString(true);
    setFilter({
      ...filter,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    });
  }

  const timeDurationList = useMemo(() => {
    let hours = [],
      minutesListSixtyMinuteGap = [],
      minutesListOneMinuteGap = [];

    new Array(24).fill(null).forEach((_, index) => {
      hours.push(moment.tz({ hour: index }, timeZone).format("HH:mm"));
    });

    new Array(24).fill(null).forEach((_, hourIndex) => {
      new Array(60).fill(null).forEach((_, minuteIndex) => {
        minutesListOneMinuteGap.push(
          moment
            .tz({ hour: hourIndex, minute: minuteIndex }, timeZone)
            .format("h:mm a")
        );
      });
    });

    new Array(60).fill(null).forEach((_, minuteIndex) => {
      minutesListSixtyMinuteGap.push(
        moment.tz({ minute: minuteIndex }, timeZone).format("h:mm a")
      );
    });
    return {
      hours,
      minutesListOneMinuteGap,
      minutesListSixtyMinuteGap,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const motionList = useMemo(() => {
    return !timelineFullRecordings.length
      ? []
      : timelineFullRecordings.map((recording) => {
          return moment.tz(recording.start_time, timeZone).format("HH:mm");
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineFullRecordings, timeZone, timelineDate]);

  const filterMotionListUniqueHours = useMemo(() => {
    const array = motionList.map((time) => time.substring(0, 2));

    const uniqueHourSet = new Set(array);
    const uniqueHourArray = Array.from(uniqueHourSet);
    return uniqueHourArray.sort();
  });

  const handlesubmit = (hour, minute, index) => {
    handleTimeframeSelection(hour, minute, index);
  };

  const getQuarterlyTimeArray = (time) => {
    const [hour, minute] = time.split(":").map(String);
    const starttimelineMoment = moment
      .tz(timelineDate, timeZone)
      .startOf("day");
    const endtimelineMoment = moment.tz(timelineDate, timeZone).startOf("day");

    const startDatetime = starttimelineMoment
      .add(hour, "hour")
      .add(minute, "minute")
      .toISOString(true);
    const endDatetime = endtimelineMoment
      .add(hour, "hour")
      .add(59, "minute")
      .toISOString(true);
    setFilter({
      ...filter,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    });
    let hourlyRecordings = motionList.filter((list) => list.startsWith(hour));
    let tempTimeRanges = {};

    for (let i = 0; i < 4; i++) {
      const startMinute = i * 15;
      const endMinute = (i + 1) * 15 - 1;

      let quarterlyData = [];
      hourlyRecordings.map((item) => {
        const [hour, minute] = item.split(":").map(String);
        if (startMinute < minute && endMinute > minute) {
          quarterlyData.push(item);
        }
      });
      const startTime = `${hour.toString(0, 2).padStart(2, "0")}:${startMinute
        .toString()
        .padStart(2, "0")}`;
      const endTime = `${hour.toString(0, 2).padStart(2, "0")}:${endMinute
        .toString()
        .padStart(2, "0")}`;

      const timeRange = `${startTime} - ${endTime}`;
      tempTimeRanges[timeRange] = quarterlyData;
    }
    setTimeRangeArray(tempTimeRanges);
  };

  const getQuarterlyData = async (time) => {
    setshow(true);
    let selectedTime = moment(time).tz(timeZone).format("HH:mm");
    const [hour, minute] = selectedTime.split(":").map(String);
    const starttimelineMoment = moment
      .tz(timelineDate, timeZone)
      .startOf("day");
    const endtimelineMoment = moment.tz(timelineDate, timeZone).startOf("day");
    const startDatetime = starttimelineMoment
      .add(hour, "hour")
      .add(minute, "minute")
      .toISOString(true);
    const endDatetime = endtimelineMoment
      .add(hour, "hour")
      .add(59, "minute")
      .toISOString(true);
    setFilter({
      ...filter,
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    });
    let params = {
      summary_start: time,
      duration: "PT15M",
      block_count: 4,
    };

    if (data && data.device_uuid) {
      params.device_uuid__in = data.device_uuid.join(",");
    }
    await apiRequest(
      "get",
      `api/1/timeline-recordings/summary/`,
      "",
      null,
      false,
      { success: "", error: "", showToast: false },
      params
    ).then((res) => {
      let response = res.data,
        data = [],
        temp;
      if (response.blocks.length > 0) {
        for (let i = 0; i < response.blocks.length; i++) {
          let startTime = response.blocks[i].block_start;
          let endTime = response.blocks[i].block_end;
          let str = `${moment(startTime)
            .tz(timeZone)
            .format("kk:mm")} - ${moment(endTime)
            .tz(timeZone)
            .format("kk:mm")}`;
          temp = { ...response.blocks[i], string: str };
          data.push(temp);
        }
      }
      setTimeQuarterlyBlock(data);
    });
  };

  const getTimeBlock = async () => {
    let params = {
      summary_start: filter.startDatetime,
      duration: "PT1H",
      block_count: 24,
    };
    if (filter.group_uuid) params.group_uuid = filter.group_uuid;
   
    if (data && data.device_uuid) {
      params.device_uuid__in = data.device_uuid.join(",");
    }

    await apiRequest(
      "get",
      `api/1/timeline-recordings/summary/`,
      "",
      null,
      false,
      { success: "", error: "" },
      params
    ).then((res) => {
      setTimeBlock(res.data.blocks);
    });
  };

  useEffect(() => {
    getTimeBlock();
  }, []);

  useEffect(() => {
    if (timelineParams.startDatetime) {
      setTimelineDate(
        moment.tz(timelineParams.startDatetime, timeZone).toISOString(true)
      );
    }
  }, [timelineParams.startDatetime]);

  return (
    <div className="timeline-wrapper">
      <div className="heading">
        <h4>Date: {moment.tz(timelineDate, timeZone).format("DD-MM-YYYY")}</h4>
        <div className="button-wrap">
          <button
            className="btn button-prev"
            onClick={() => handleDateChange("prev")}
          >
            Prev
          </button>
          <button
            className="btn button-next"
            onClick={() => handleDateChange("next")}
          >
            Next
          </button>
        </div>
      </div>

      <div className="timeline-outer mt-2">
        <div className="timeline">
          <div className="timeline-overflow">
            <div className="hour-listing-new">
              <ul className="hours-list">
                {timeBlock.map((hour, hourIndex) => (
                  <li
                    key={hourIndex}
                    className={hour.recordings_count > 0 ? "inactive" : ""}
                    onClick={() => getQuarterlyData(hour.block_start)}
                  >
                    {moment(hour.block_start).tz(timeZone).format("kk:mm")}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {show && timeQuarterlyBlock.length > 0 && (
        <div className="timeline-outer hour-list-quarter mt-2">
          <div className="timeline">
            <div className="hour-listing-new">
              <ul className="hours-list">
                {timeQuarterlyBlock.map((time, index) => (
                  <li
                    key={index}
                    className={time.recordings_count > 0 ? "inactive" : ""}
                    onClick={() => {
                      handleTimeChange(time.string);
                    }}
                  >
                    {time.string}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
