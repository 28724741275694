import React, { useEffect, useState } from 'react'
import { useUiContext } from '../../../contexts/UiContext'
import HeaderDashboard from '../../common/HeaderDashboard'
import SettingNavigation from '../../common/SettingNavigation'

export default function DevicesStorage({route}) {
    let uid = localStorage.getItem("customeruuid")
    const { apiRequest } = useUiContext();
    const [data, setData] = useState([]);
    const endpoint = (route === 'customer')?'customers':(route === 'reseller')?'sellers':'distributors'
    const getCutomerId = async () => {
            const idHeaders = {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
            }
            const res = await apiRequest("get", `api/1/${endpoint}/${uid}/get_summary_device_storage_for/`, "", idHeaders, true)
            if (res) {
                setData(res.data.rows)
            }

    }

    const isNumeric = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    useEffect(() => {
        getCutomerId();
    }, [])


    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <SettingNavigation />
                    <div className="flex-grow-1 setting-page-wrapper">
                        <div className="container">
                            <div className='user-permissions usr-prm devices-storage-wrapper'>
                                <div className="devices-storage-list">
                                    <div className="theme-table">
                                        <div className="table-responsive">
                                            <table className="table">
                                            <thead>
                                                    {data.map((rows,i)=>(
                                                        <tr key={i}>
                                                            {i==0&&rows.map((row,index)=>(
                                                            <th key={index}>{row}  </th>
                                                            ))}
                                                        </tr>
                                                        ))}
                                                    </thead>
                                                    <tbody>
                                                    {data.map((rows,i)=>(
                                                        <tr key={i}>
                                                            {i>0&&rows.map((row,index)=>(
                                                            <td key={index}>{row}  </td>
                                                            ))}
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
