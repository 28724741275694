import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUiContext } from "../../../contexts/UiContext";
import DetailsSubmenu from "../../common/DetailsSubmenu";
import HeaderDashboard from "../../common/HeaderDashboard";
import Leaflet from "../../common/Leaflet";
import RecordingDetailsSidebar from "../../common/RecordingDetailsSidebar";
import { getLocal } from "../../../helper/common";

import Svg from "../../common/Svg";
import { useRef } from "react";
import { Layer, Rect, Stage, Transformer, Image, Text } from "react-konva";
import Konva from "konva";
import Knob from "../../../component/knob";
import { toast } from "react-hot-toast";
import Select from "react-select";

export default function Details({ route }) {
  let role = getLocal("role");
  const [rotation, setRotation] = useState(0);
  const [isCanvasRefresh, setIsCanvasRefresh] = useState(false);

  let navigate = useNavigate();
  const { apiRequest } = useUiContext();
  const location = useLocation();
  const { device_uuid } = useParams();
  const [deviceID, setDeviceID] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [groups, setGroups] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const [createGroup, setCreateGroup] = useState("");
  const [formData, setFormData] = useState({
    is_active: "",
    serialNumber: "",
    cameraLocation: "",
    // lat: "",
    // lon: "",
    sync_device_recordings: false,
    group_uuid: "",
    name: "",
    password: "",
    username: "",
    sitemap_uuid: "",
    address_uuid: "",
    status: "",
    sitemap_bearing: 0,
    sitemap_x_fraction: 0,
    sitemap_y_fraction: 0,
  });
  const [image, setImage] = useState(new window.Image());
  const imageRef = useRef();
  const [selectedId, selectShape] = React.useState(null);
  const stageParentDiv = useRef();
  const stageMobileParentDiv = useRef();
  const [dimensions, setDimensions] = useState({ width: "", height: "" });
  const [dimensionsMobile, setDimensionsMobile] = useState({ width: "", height: "" });
  const [addresses, setAddresses] = useState([]);
  const [siteMapList, setSiteMapList] = useState([]);
  const [selectedSiteMap, setSelectedSiteMap] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);

  const [attrs, setAttrs] = useState({
    id: 1,
    isDragging: false,
    x: 50,
    y: 50,
    rotation: rotation,
    selected: false,
    isActive: true,
  });

  useEffect(() => {
    setAttrs({ ...attrs, rotation: rotation });
  }, [rotation]);

  useEffect(() => {
    if (
      stageParentDiv.current?.offsetHeight &&
      stageParentDiv.current?.offsetWidth
    ) {
      setDimensions({
        width: stageParentDiv.current.offsetWidth,
        height: stageParentDiv.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (
      stageMobileParentDiv.current?.offsetHeight &&
      stageMobileParentDiv.current?.offsetWidth
    ) {
      setDimensions({
        width: stageMobileParentDiv.current.offsetWidth,
        height: 550,
      });
    }
  }, []);

  useEffect(() => {
    if (deviceDetails) {
      let percentageX = getPercentage(attrs.x, dimensions.width);
      let percentageY = getPercentage(attrs.y, dimensions.height / 2);
      setFormData({
        ...formData,
        sitemap_bearing: parseInt(attrs.rotation),
        sitemap_x_fraction: parseFloat(percentageX).toFixed(2),
        sitemap_y_fraction: parseFloat(percentageY).toFixed(2),
      });
    }
  }, [attrs]);

  function getPercentage(value, heightOrWidth) {
    if (deviceDetails) {
      return ((value / heightOrWidth) * 100) / 100;
    }
  }

  useEffect(() => {
    if (location) {
      setDeviceID(location.search.split("=")[1]);
    }
    getGroups();
  }, [location]);

  useEffect(() => {
    if (device_uuid) getDeviceDetails(device_uuid);
  }, [device_uuid]);

  // Create group API
  const createHandler = (e) => {
    e.preventDefault();
    newGroup(createGroup);
  };

  const newGroup = async (createGroup) => {
    let header = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    let uid = localStorage.getItem("customeruuid");
    let res = await apiRequest(
      "post",
      "api/1/groups/",
      { customer_uuid: uid, name: createGroup },
      header,
      true
    );
  };

  useEffect(() => {
    if (deviceDetails) {
      getAddressList();
      setAttrs({
        ...attrs,
        x: deviceDetails.sitemap_x_fraction * dimensions.width,
        y: deviceDetails.sitemap_y_fraction * (dimensions.height / 2),
        rotation: deviceDetails.sitemap_bearing,
        isActive: deviceDetails.is_active ? true : false,
      });

      setFormData({
        ...formData,
        is_active: deviceDetails.is_active,
        name: deviceDetails.name,
        serialNumber: deviceDetails.mac,
        cameraLocation: deviceDetails.name,
        // lat: deviceDetails.lat,
        // lon: deviceDetails.lon,
        username: deviceDetails.username,
        password: deviceDetails.password,
        status: deviceDetails.status,
        sync_device_recordings: deviceDetails.sync_device_recordings,
        group_uuid: deviceDetails.groups[0]?.group_uuid,
        address_uuid: deviceDetails.address_uuid,
        sitemap_uuid: deviceDetails.sitemap_uuid,
      });
      setSelectedSite({
        value: deviceDetails.address_uuid,
        label: deviceDetails.address_name,
      });
      setSelectedSiteMap({
        value: deviceDetails.sitemap_uuid,
        label: deviceDetails.sitemap?.name,
      });
      const img = new window.Image();
      img.src = deviceDetails.sitemap ? deviceDetails.sitemap.image_map : "";
      setImage(img);
    }
  }, [deviceDetails]);

  useEffect(() => {
    if (image && imageRef.current !== null)
      imageRef.current.getLayer().batchDraw();
    // if(cameraImg) cameraImgRef.current.getLayer().batchDraw();
  }, [image]);

  const getDeviceDetails = async (id) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    await apiRequest("get", `api/1/devices/${id}`, "", headers, true).then(
      (res) => {
        setDeviceDetails(res.data);
        if (res.data.address_uuid) {
          getDeviceAddressSitemap(res.data.address_uuid, res.data);
        }
        setRotation(res.data.sitemap_bearing);
        if (res.data.sitemap_uuid) {
          setFormData({
            ...formData,
            sitemap_uuid: res.data.sitemap_uuid,
            address_uuid: res.data.address_uuid,
          });
        }
      }
    );
  };

  const getAddressList = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest("get", "api/1/addresses/", "", headers, true);
    if (res) {
      let options = [];
      options.push({
        value: null,
        label: "Select Site",
      });
      res.data.results.map((ele) => {
        options.push({
          value: ele.address_uuid,
          label: ele.name,
        });
      });
      setAddresses(options);
      if (deviceDetails.address_uuid) {
        let site = options.filter(
          (data) => data.value === deviceDetails.address_uuid
        );
        setSelectedSite(site);
      }
    }
  };

  const getDeviceAddressSitemap = async (
    address_uuid,
    deviceDetail = deviceDetails
  ) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "get",
      `api/1/addresses/${address_uuid}/`,
      "",
      headers,
      true
    );
    if (res) {
      let options = [];
      options.push({
        value: null,
        label: "Select Site map",
        image: null,
      });
      if (address_uuid) {
        res.data.sitemaps.map((ele) => {
          options.push({
            value: ele.sitemap_uuid,
            label: ele.name,
            image: ele.image_map,
          });
        });
      }
      setSiteMapList(options);
      if (deviceDetail.sitemap) {
        let siteMap = options.filter(
          (data) => data.value === deviceDetail.sitemap.sitemap_uuid
        );
        setSelectedSiteMap(siteMap);
      }
    }
  };

  const handleRangeChange = (value) => {
    setRotation(value);
  };

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const deleteHandler = async () => {
    deleteDevice(device_uuid);
  };
  const deleteDevice = async (device_uuid) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "delete",
      `api/1/devices/${device_uuid}/`,
      "",
      headers,
      true,
      { success: "Camera has been removed", error: "Failed to removed camera" }
    );
    if (res) {
      navigate("/customer/cameras");
    }
  };

  const getGroups = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", "api/1/groups/", "", headers, false)
      .then((res) => {
        if (res.data?.results !== null) {
          setGroups(res.data.results);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deviceUpdate = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    await apiRequest(
      "patch",
      `api/1/devices/${device_uuid}/`,
      formData,
      headers,
      true,
      {
        success: "Camera detail has been update",
        error: "Failed to update camera detail",
      }
    ).then((res) => {
      getDeviceDetails(res.data.device_uuid);
    });
  };

  const deRegisterDevice = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const body = {
      name: `${deviceDetails.name}`,
      sitemap_bearing: deviceDetails.sitemap_bearing,
      sitemap_uuid: `${deviceDetails.sitemap_uuid}`,
      sitemap_x_fraction: deviceDetails.sitemap_x_fraction,
      sitemap_y_fraction: deviceDetails.sitemap_y_fraction,
    };
    const res = await apiRequest(
      "post",
      `api/1/devices/${device_uuid}/deregister_device_oak/`,
      body,
      headers,
      true,
      {
        success: "Camera Device has been deregistered",
        error: "Failed to deregister Camera Device",
      }
    )
      .then(() => {
        toast.success("Camera Device has been deregistered");
      })
      .catch(() => {
        toast.error("Failed to deregister Camera Device");
      });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const siteMapChange = (e) => {
    const img = new window.Image();
    if (e) {
      img.src = e.image;
      setImage(img);
      setFormData({ ...formData, sitemap_uuid: e.value });
    } else {
      setImage(new window.Image());
    }
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, group: e.target.value });
  };

  const cameraLink = () => {
    return (
      formData.serialNumber != "" && (
        <a
          className="btn btn-primary btn-rounded"
          href={`http://axis-${formData.serialNumber}.local/`}
          target="_blank"
        >
          Local Camera Config
        </a>
      )
    );
  };

  const pinDrag = async (latLng) => {
    setFormData({...formData, lat: latLng.lat, lon: latLng.lng})
  }

  const viewPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;

  const clearSelectedSiteData = () => {
    setSelectedSite(null);
    setSelectedSiteMap(null);
  };

  return (
    <>
      <div className="d-flex flex-column site-main-wrapper grid-page">
        <HeaderDashboard />
        <div className="flex-grow-1 main-wrapper">
          {/* listing page */}

          <div className="main-page-height devices-page d-flex">
            <RecordingDetailsSidebar
              route={route}
              canvasRefresh={isCanvasRefresh}
              clearSelectedSiteData={clearSelectedSiteData}
            />
            <div className="flex-grow-1">
              <div className="d-flex flex-column">
                <div className="camera-header d-flex align-items-center details-header">
                  <div className="dropdown">
                    <button
                      className="btnn"
                      type="button"
                      id="dropdownSorting"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Svg name="camera-active" />
                    </button>
                  </div>
                  <div className="flex-grow-1 available-heading">
                    <h2>{deviceDetails?.name}</h2>
                  </div>
                  <div className="button-wrap">
                    <button
                      className="btn btn-delete"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                    >
                      <Svg name="delete-icon" />
                    </button>
                  </div>
                </div>
                <div className="flex-grow-1 details-view">
                  <DetailsSubmenu route={route} />
                  {isAdmin ? (
                    <div className="add-new-device-page">
                      <div className="row add-dvc-row gx-0">
                        <div className="col-xl-6">
                          <div className="theme-form-wrapper">
                            <div className="form">
                              <div className="d-flex mb__20">
                                <label className="col-form-label text-end"></label>
                                <div className="flex-grow-1">
                                  <div className="text-end new-tab-text">
                                    {cameraLink()}
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mb__20">
                                <label
                                  htmlFor="deviceCameraName"
                                  className="col-form-label text-end"
                                >
                                  Camera Name
                                </label>
                                <div className="flex-grow-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="deviceCameraName"
                                    placeholder="Camera name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="d-flex mb__20">
                                <label
                                  htmlFor="deviceCameraStatus"
                                  className="col-form-label text-end"
                                >
                                  Status
                                </label>
                                <div className="flex-grow-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="deviceCameraStatus"
                                    placeholder="Camera name"
                                    name="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="d-flex mb__20">
                                <label
                                  htmlFor="deviceSerialNumber"
                                  className="col-form-label text-end"
                                >
                                  Serial Number
                                </label>
                                <div className="flex-grow-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="deviceSerialNumber"
                                    placeholder="Serial Number"
                                    name="serialNumber"
                                    value={formData.serialNumber}
                                    onChange={handleChange}
                                  />
                                  <div className="form-text">MAC Address</div>
                                </div>
                              </div>
                              <div className="d-flex mb__20">
                                <label
                                  htmlFor="cameraLocation"
                                  className="col-form-label text-end"
                                >
                                  Camera Location
                                </label>
                                <div className="flex-grow-1">
                                  <input
                                    type="text"
                                    onChange={handleChange}
                                    value={formData.cameraLocation}
                                    className="form-control"
                                    id="cameraLocation"
                                    name="cameraLocation"
                                    placeholder="Camera Location"
                                  />
                                </div>
                              </div>
                              <div className="d-flex mb__20">
                                <label
                                  htmlFor="address_uuid"
                                  className="col-form-label text-end"
                                >
                                  Site
                                </label>
                                <div className="flex-grow-1">
                                  <Select
                                    className="form-control react-select-container"
                                    classNamePrefix="react-select"
                                    name="address_uuid"
                                    options={addresses}
                                    value={selectedSite}
                                    onChange={(e) => {
                                      setSelectedSite(e);
                                      if (e.value == null) {
                                        siteMapChange();
                                        setSelectedSiteMap(null);
                                      }
                                      getDeviceAddressSitemap(e.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="d-flex mb__20">
                                <label
                                  htmlFor="sitemap"
                                  className="col-form-label text-end"
                                >
                                  Sitemap
                                </label>
                                <div className="flex-grow-1">
                                  <Select
                                    className="form-control react-select-container"
                                    classNamePrefix="react-select"
                                    name="sitemap_uuid"
                                    options={siteMapList}
                                    value={selectedSiteMap}
                                    onChange={(e) => {
                                      setSelectedSiteMap(e);
                                      siteMapChange(e);
                                      if (e.value) {
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              {selectedSite?.value !== null && <div className="col-sm-12 col-md-12 d-sm-block d-md-block d-lg- d-xl-none" ref={stageMobileParentDiv}>
                          <Stage
                            width={dimensions.width}
                            height={dimensions.height / 2}
                            onMouseDown={checkDeselect}
                            onTouchStart={checkDeselect}
                          >
                            <Layer>
                              <Image
                                width={dimensions.width}
                                height={dimensions.height / 2}
                                x={0}
                                y={0}
                                image={image}
                                ref={imageRef}
                              />
                              <Draw {...{ attrs, setAttrs }} />
                            </Layer>
                          </Stage>
                          <div className="row mt-2 p-lg-0 p-3 mb-lg-0 mb-5">
                            <h6 className="p-0">
                              Rotate the dial for change the camera orientation
                              on the map
                            </h6>
                            <div className="mt-2 mb-3 col-xl-12 text-center">
                              <Knob
                                numTicks={125}
                                degrees={360}
                                min={1}
                                max={360}
                                value={rotation}
                                onChange={(value) => {
                                  handleRangeChange(value);
                                }}
                                onTouchStart={(value) => {
                                  handleRangeChange(value);
                                }}
                              />
                            </div>
                          </div>
                        
                        </div>}

                              {role === "customerIsAdmin" && (
                                <div>
                                  <div className="d-flex mb__20">
                                    <label
                                      htmlFor="deviceUsername"
                                      className="col-form-label text-end"
                                    >
                                      Username
                                    </label>
                                    <div className="flex-grow-1">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="deviceUsername"
                                        placeholder="username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex mb__20 ">
                                    <label
                                      htmlFor="devicePassword"
                                      className="col-form-label text-end"
                                    >
                                      Password
                                    </label>
                                    <div className="flex-grow-1 position-relative">
                                      <input
                                        type={passwordType}
                                        className="form-control"
                                        id="devicePassword"
                                        placeholder="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                      />
                                      <span
                                        className={
                                          "forgot-wrap " + passwordType
                                        }
                                        onClick={
                                          isAdmin ? () => viewPassword() : null
                                        }
                                      >
                                        <i
                                          className="fa fa-eye "
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="sync-device-checkbox pt-3">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="syncRecording"
                                    checked={formData.sync_device_recordings}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        sync_device_recordings:
                                          e.target.checked,
                                      })
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="syncRecording"
                                  >
                                    <p>Sync device recordings</p>
                                    <span>
                                      Copy to Cloud if Storage Subscribed
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <hr />
                              <div className="d-flex mb__20 align-items-center">
                                <label className="col-form-label text-end">
                                  Is Active ?
                                </label>
                                <div className="flex-grow-1">
                                  <label className="switch" id="isActive">
                                    <input
                                      type="checkbox"
                                      id="isActive"
                                      checked={formData.is_active}
                                      onChange={(e) =>
                                        setFormData({
                                          ...formData,
                                          is_active: e.target.checked,
                                        })
                                      }
                                      defaultChecked
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                </div>
                              </div>
                              <hr />
                              <fieldset className="d-flex mb__20 group-checkbox align-items-center">
                                <legend className="col-form-label text-end mt-0">
                                  Groups
                                </legend>
                                <div className="flex-grow-1">
                                  <div className="d-flex flex-wrap group-checkbox-wrap align-items-center">
                                    {groups?.map((group, index) => (
                                      <div className="form-check" key={index}>
                                        {formData.group_uuid ===
                                        group.group_uuid ? (
                                          <input
                                            type="radio"
                                            name="group"
                                            id={group.group_uuid}
                                            value={group.group_uuid}
                                            onChange={handleRadioChange}
                                            checked
                                          />
                                        ) : (
                                          <input
                                            type="radio"
                                            name="group"
                                            id={group.group_uuid}
                                            value={group.group_uuid}
                                            onChange={handleRadioChange}
                                          />
                                        )}

                                        <label
                                          className="form-check-label"
                                          htmlFor={group.group_uuid}
                                        >
                                          {group.name}
                                        </label>
                                      </div>
                                    ))}
                                    <div className="form-check">
                                      <button
                                        className="add-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#addModal"
                                      >
                                        <Svg name="add-group" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <hr />
                              <div className="d-flex pt-3">
                                <legend className="col-form-label text-end m-0 d-none d-xl-flex"></legend>
                                <div className="flex-grow-1 text-center text-xl-start">
                                  <button
                                    className="btn btn-primary btn-rounded w-125px"
                                    onClick={deviceUpdate}
                                  >
                                    Apply
                                  </button>

                                  <button
                                    className="btn btn-secondary orange-color btn-rounded w-auto px-4  ms-3"
                                    onClick={() => {
                                      deRegisterDevice();
                                    }}
                                  >
                                    De-register Device
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedSite?.value !== null && <div className="col-xl-6 d-none d-lg-block " ref={stageParentDiv}>
                          <Stage
                            width={dimensions.width}
                            height={dimensions.height / 2}
                            onMouseDown={checkDeselect}
                            onTouchStart={checkDeselect}
                          >
                            <Layer>
                              <Image
                                width={dimensions.width}
                                height={dimensions.height / 2}
                                x={0}
                                y={0}
                                image={image}
                                ref={imageRef}
                                preventDefault={false}
                              />
                              <Draw {...{ attrs, setAttrs }} />
                            </Layer>
                          </Stage>
                          <div className="row mt-5 p-lg-0 p-3 mb-lg-0 mb-5">
                            <h6 className="p-lg-0 p-3">
                              Rotate the dial for change the camera orientation
                              on the map
                            </h6>
                            <div className="mt-5 col-xl-12 text-center">
                              <Knob
                                numTicks={125}
                                degrees={360}
                                min={1}
                                max={360}
                                value={rotation}
                                onChange={(value) => {
                                  handleRangeChange(value);
                                }}
                                onTouchStart={(value) => {
                                  handleRangeChange(value);
                                }}
                              />
                            </div>
                          </div>
                        </div>}

                        
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade messageModal"
        id="successModal"
        tabIndex={-1}
        aria-labelledby="successModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-text text-center success">
                <div className="icon-wrap">
                  <Svg name="success-icon" />
                </div>
                <h2>Success!</h2>
                <p>Your changes has been saved successfully</p>
              </div>

              {/* <div className="modal-text text-center failure">
                                <div className="icon-wrap">
                                    <Svg name="failure-icon" />
                                </div>
                                <h2>Oops!</h2>
                                <p>Your changes has been saved successfully</p>
                            </div> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade add-modal"
        id="addModal"
        tabIndex={-1}
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {isAdmin ? (
              <div className="modal-header">
                <h5 className="modal-title text-center">Create new group</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <Svg name="close-rounded" />
                </button>
              </div>
            ) : null}
            <div className="modal-body">
              <div className="theme-form-wrapper">
                <form>
                  <div className="d-flex mb__20 align-items-center">
                    <label
                      htmlFor="groupName"
                      className="col-form-label text-end"
                    >
                      Group name
                    </label>
                    <div className="flex-grow-1">
                      <input
                        type="text"
                        className="form-control"
                        id="groupName"
                        onChange={(e) => setCreateGroup(e.target.value)}
                        placeholder="Group name"
                      />
                    </div>
                  </div>
                  <div className="button-wrap text-center">
                    <button
                      className="btn btn-primary btn-rounded"
                      type="button"
                      onClick={createHandler}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-delete"
        id="deleteModal"
        tabIndex={-1}
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-wrap">
                <h4>Are you sure you want to delete this device? </h4>
                <div className="note">
                  <div className="d-flex heading">
                    <Svg name="delete-modal-icon" />
                    <div className="flex-grow-1 ps-3">Warning</div>
                  </div>
                  <p>All logs for this device will also be deleted.</p>
                </div>
              </div>
              <div className="button-wrap d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-delete-new"
                  data-bs-dismiss="modal"
                  onClick={deleteHandler}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg
        style={{ display: "none" }}
        id="svgDeactive"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        // height="30"
        // width="20"
        fill="#e82929"
      >
        <path d="M80-160v-640h640v275l160-160v410L720-435v275H80Z" />
      </svg>
      <svg
        style={{ display: "none" }}
        id="svgActive"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        // height="10"
        // width="10"
        fill="#3bb44a"
      >
        <path d="M80-160v-640h640v275l160-160v410L720-435v275H80Z" />
      </svg>
    </>
  );
}

function Draw({ attrs, setAttrs }) {
  const [cameraImg, setCameraImg] = useState(new window.Image());
  const cameraImgRef = useRef();
  const TransformRef = useRef();

  useEffect(() => {
    let svgId = attrs.isActive
      ? document.getElementById("svgActive")
      : document.getElementById("svgDeactive");
    const svgData = encodeURIComponent(
      new XMLSerializer().serializeToString(svgId)
    );
    // const cameraImg1 = new window.Image();
    cameraImg.src = "data:image/svg+xml;charset=utf-8," + svgData;
    setCameraImg(cameraImg);
  }, [attrs]);

  const onDragEnd = ({ target }) => {
    setAttrs({
      ...attrs,
      x: target.x(),
      y: target.y(),
      width: target.width(),
      height: target.height(),
    });
  };

  // const onTransform = () => {
  //   const node = cameraImgRef.current;
  //   const width = node.width();
  //   const height = node.height();

  //   // now using x and y position
  //   const x = node.x();
  //   const y = node.y();
  //   const rotation = node.rotation();

  //   setAttrs({ ...attrs, width, height, rotation, x, y });
  // };

  // useEffect(() => {
  //   TransformRef.current.setNode(cameraImgRef.current);
  //   TransformRef.current.getLayer().batchDraw();
  // }, []);

  return (
    <Image
      image={cameraImg}
      ref={cameraImgRef}
      {...attrs}
      draggable={true}
      onDragEnd={onDragEnd}
      height={30}
      width={32}
    />
  );
}
