import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useUiContext } from '../../../../contexts/UiContext';
import { formValidation, getLocal, checkUserRoutePermission } from '../../../../helper/common';
import HeaderDashboard from '../../../common/HeaderDashboard'
import SettingNavigation from '../../../common/SettingNavigation'
import Svg from '../../../common/Svg'
import { SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER, SINGLE_SITE_USER } from '../../../../constants/common';

const EditUser = ({route}) => {
    let role = getLocal("role")
    let userRole = getLocal('userRole');
    const { apiRequest } = useUiContext();
    let params = useParams();
    const navigate  = useNavigate();
    var uid = localStorage.getItem("customeruuid")
    const [isDisabled, setIsDisabled] = useState(false)
    const endpoint = (route === 'customer') ? 'users' : (route === 'reseller') ? 'seller-users' : (route === 'distributor') ? 'distributor-users' : '';
    const profile = (route === 'customer') ? 'customer' : (route === 'reseller') ? 'seller' : (route === 'distributor') ? 'distributor' : '';
    const [firstNameError, setFirstNameError] = useState(false)
    const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('')
    const [lastNameError, setLastNameError] = useState(false)
    const [lastNameErrorMessage, setLastNameErrorMessage] = useState('')
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [roleError, setRoleError] = useState(false)
    const [data, setData] = useState([]);
    const [userName, setUserName] = useState(params.username)
    const [accessLevelList, setAccessLevelList] = useState({})
    const [accessLevel, setAccessLevel] = useState('')
    const [userInitialRole, setuserInitialRole] = useState('')
    const [userInitialAddress, setuserInitialAddress] = useState([])
    const [roleAddress, setRoleAddress] = useState([])
    const [selectedRoleAddress, setSelectedRoleAddress] = useState([])
    const [userApiCall, setUserApiCall] = useState(false)
    const [formData, setFormData] = useState({
        email: '',
        addresses: [],
        customer_uuid: '',
        customer_profile_url: '',
        customer_url:'',
        is_admin: false,
        role:'',
        first_name: '',
        last_name: '',
    })

    const body = {
        [`${profile}_profile`]: {
            [`${profile}_uuid`]: uid,
            [`is_${profile}_admin`]: formData.is_admin,
        },
        email: formData.email,
        first_name: formData.first_name,
        last_name: formData.last_name
    }
    useEffect(() => {
        let { first_name, last_name } = formData;
        formValidation({ firstname: first_name, lastName: last_name }, setIsDisabled, setEmailError)
    }, [formData])

    const handleChange = (e) => {
        if(e.target.name === 'first_name' || e.target.name === 'last_name' || e.target.name === 'email'){
            setUserApiCall(true);
        }
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const updateUser = async (e) => {
        let userUpdateRes, profileUpdateRes;
        e.preventDefault();
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        if(userApiCall === true){
            userUpdateRes = await apiRequest('patch', `api/1/${endpoint}/${userName}/`, {
                first_name: body.first_name,
                last_name: body.last_name,
                email: body.email
            }, headers, true, {success: 'Details updated successfully', error: 'Details not updated'})
            if(userUpdateRes.status !== 200) {
                if(userUpdateRes.response.status === 400){
                    let data = userUpdateRes.response.data;
                    if(data?.first_name && data.first_name.length > 0){
                        setFirstNameError(true);
                        setFirstNameErrorMessage(data.first_name[0])
                    }
                    if(data?.email && data.email.length > 0){
                        setEmailError(true);
                        setEmailErrorMessage(data.email[0])
                    }
                    if(data?.last_name && data.last_name.length > 0){
                        setLastNameError(true);
                        setLastNameErrorMessage(data.last_name[0])
                    }
                }
            }
        }
        let tempBody =  body;
        if(profile === 'customer'){
            tempBody = { 
                [`${profile}_uuid`]: uid,
                [`role`]: accessLevel,
                [`addresses`]: formData.addresses,
            }
        }else{
            tempBody = {
                [`is_${profile}_admin`]: formData.is_admin,
            }
        }
        profileUpdateRes = await apiRequest('patch', `api/1/${profile}-profiles/${userName}/`, tempBody, headers, true, { success: 'Updated Successfully', error: 'User not updated' })
        if (profileUpdateRes) {
            if (profileUpdateRes.status !== 200) {
                if(profileUpdateRes.response.status === 400){
                    let data = profileUpdateRes.response.data;
                    if(data){
                        if(data?.email && data.email.length > 0){
                            setEmailError(true);
                            setEmailErrorMessage(data.email[0])
                        }
                        if(data.customer_profile?.role && data.customer_profile?.role.length > 0){
                            setRoleError(true)
                        }
                    }
                }
            }
        }
        setUserApiCall(false);
        if((userUpdateRes?.status === 200 && profileUpdateRes?.status === 200) || 
            (!userApiCall && profileUpdateRes?.status === 200 )){
            navigate(`/${route}/users-and-permissions`)
        }else{
            setIsDisabled(true)
        }
    }

    const getAccessLevelList = async (role) => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        await apiRequest('get', `api_auth/1/form_choices`, {}, headers, false, {})
        .then((res) => {
            if(res){
                let response = res.data;
                let rolesPriority = response.user_customer_profile_role_admin_priorities;
                let priority = rolesPriority[userRole];
                const filteredData = Object.fromEntries(
                    Object.entries(rolesPriority).filter(([key, value]) => value <=  priority)
                );
                setAccessLevelList(filteredData)
            }
        })
        .catch((error) => {
            console.log('error: ',error)
        })
    }

    const getUserDetails = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        let params = {
            username: userName
        }
        await apiRequest('get', `api/1/${endpoint}/`, {}, headers, false, {}, params)
        .then((res) => {
            if(res){
                let response = res.data.results[0];
                if(response.distributor_profile || response.seller_profile){
                    setFormData({
                        ...formData,
                        email: response.email,
                        first_name: response.first_name,
                        last_name: response.last_name,
                        is_admin: endpoint !== 'seller-users' ? 
                            response.distributor_profile.is_distributor_admin : 
                            response.seller_profile.is_seller_admin,
                    })
                }
                if(response.customer_profile){
                    let role = response.customer_profile.role;
                    setuserInitialRole(role);
                    setuserInitialAddress(response.customer_profile.addresses)
                    setFormData({
                        ...formData,
                        role: role,
                        email: response.email,
                        first_name: response.first_name,
                        last_name: response.last_name,
                        is_admin: response.customer_profile.is_customer_admin,
                        addresses: response.customer_profile.addresses
                    })
                    setSelectedRoleAddress(response.customer_profile.addresses);
                    setAccessLevel(role)
                    getAccessLevelList(role)
                }
            }
        })
        .catch((error) => {
            console.log('error: ',error);
        })
    }
   
    const getUserRoleAddress = async () => {
        let addresses = [];
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        await apiRequest('get', 'api/1/my-user/', {}, headers, false, {})
        .then((res) => {
            let response = res.data;
            if(response) {
                addresses = response.results[0].customer_profile.role_addresses;
                setRoleAddress(addresses);
            }
        }).catch((error) => {
            console.log('error: ',error)
        })
    }

    useEffect(() => {
        getAccessLevelList()
        getUserDetails();
        getUserRoleAddress();
    }, [])

    useEffect(() => {
        if(roleAddress.length !== 0) setRoleAddress(roleAddress)
    }, [roleAddress])    

    const handleCheckboxChange = (index, value, checked,address) => {
        if (checked) {
            if(accessLevel === SINGLE_SITE_ADMIN ||
            accessLevel === SINGLE_SITE_SUPER_USER ||
            accessLevel === SINGLE_SITE_USER){
                setSelectedRoleAddress([address])
            } else{
                setSelectedRoleAddress([...selectedRoleAddress, address])
            }
        } else {
            let obj1 = [...selectedRoleAddress];
            obj1.splice(obj1.findIndex((item) => {return item.address_uuid === address.address_uuid }),1);
            setSelectedRoleAddress(obj1)
            }
        } 
        
        useEffect(() => {
        setSelectedRoleAddress(selectedRoleAddress)
        setFormData({...formData, addresses: selectedRoleAddress})
    }, [selectedRoleAddress])
    
    useEffect(() => {
        if(accessLevel === userInitialRole){
            setSelectedRoleAddress(userInitialAddress)
        }else{
            setSelectedRoleAddress([])
        }
        setFormData({ ...formData, role: accessLevel})
    }, [accessLevel])
  return (
    <div className='d-flex flex-column site-main-wrapper setting-body'>
        <HeaderDashboard />
        <div className="flex-grow-1 main-wrapper">
            <div className="main-page-height settings-page d-flex flex-column">
                <SettingNavigation />
                <div className="flex-grow-1 setting-page-wrapper">
                    <div className="container">
                        <div className="user-permissions">
                            {checkUserRoutePermission(role, `/${route}/users-and-permissions`) &&
                                <Link to={`/${route}/users-and-permissions`} className="back-btn d-inline-flex align-items-center">
                                    <Svg name="left-arrow" /> Back
                                </Link>}
                            <div className="create-user">
                                <div className="page-heading">
                                    <h3>Edit user</h3>
                                </div>
                                <div className="create-user-wrapper">
                                    <div className="form-wrapper theme-form-wrapper">
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <input type="text" placeholder="FirstName" className="form-control" value={formData.first_name} onChange={handleChange} id="FirstName" name="first_name" />
                                                        { firstNameError && <div className="validation-msg" style={{top: 'calc(100% - 10rem)'}} onClick={() => {setFirstNameError(false)}}>{firstNameErrorMessage} </div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <input type="email" placeholder="Email Address" className="form-control" value={formData.email} onChange={handleChange} id="email" name="email" />
                                                        { emailError && <div className="validation-msg" style={{top: 'calc(100% - 10rem)'}} onClick={() => {setEmailError(false)}}>{emailErrorMessage} </div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <input type="text" placeholder="Last name" className="form-control" value={formData.last_name} onChange={handleChange} id="lastname" name="last_name" />
                                                        { lastNameError && <div className="validation-msg" style={{top: 'calc(100% - 6rem)'}} onClick={() => {setLastNameError(false)}}>{lastNameErrorMessage} </div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    {route === 'customer' && 
                                                        <div className="mb-3">
                                                            <select 
                                                                className="form-control text-capitalize"
                                                                value={formData.role}
                                                                onChange={(e) => {
                                                                    setAccessLevel(e.target.value)
                                                                }}>
                                                                <option value="">Select User Access Level</option>
                                                                { Object.entries(accessLevelList).map((access, i) => {
                                                                    return <option key={i} value={access[0]}>
                                                                        {access[0].replace(/_/g,' ')}
                                                                    </option>
                                                                    })
                                                                }
                                                            </select>
                                                            { roleError && <div className="validation-msg" style={{top: 'calc(100% - 8.5rem)'}} onClick={() => {setRoleError(false)}}>Please select role </div>}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            { route !== 'customer' &&
                                                <div className="mb-3 form-check">
                                                    <input type="checkbox" className="form-check-input" id="makeAdmin" name="makeAdmin" checked={formData.is_admin} onChange={(e) => setFormData({ ...formData, is_admin: e.target.checked })} />
                                                    <label className="form-check-label" htmlFor="makeAdmin">Make this user Admin</label>
                                                </div>
                                            }
                                            <div className="button-wrap">
                                                <button className="btn btn-primary btn-rounded" disabled={isDisabled} onClick={updateUser}>Update</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {roleAddress.length > 0 && 
                                    <div className="create-user-wrapper mt-3 site-accesible">
                                        <div className="heading mb-3">
                                            <h4>Site Accessible by this user</h4>
                                        </div>
                                        <div className="check-list-wrapper">
                                            {roleAddress.map((address, i) => {
                                                return(<div className="mb-2 form-check" key={i}>
                                                        <input type="checkbox" className="form-check-input" id={`site${i}`} name="SitesList"
                                                            value={address.name}
                                                            checked={selectedRoleAddress.some(e => e.address_uuid === address.address_uuid)}
                                                            onChange={(e) => {
                                                                handleCheckboxChange(i, e.target.value, e.target.checked, address)}}
                                                        />
                                                        <label className="form-check-label" htmlFor={`site${i}`}>{address.name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EditUser