import React, { useState, useEffect, useMemo } from "react";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import RefreshButton from "./RefreshButton";
import Svg from "./Svg";
import { useUiContext } from "../../contexts/UiContext";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment-timezone";

// noinspection JSUnusedLocalSymbols
export default function RecordingSidebar({
  setSortBy,
  setFilter,
  filter,
  setFilterByDate,
  deviceUuid,
  setDeviceUuid,
  setGroupUuid,
  enable,
  setEnable,
  setSelectedTimeframe,
  resetTimelineSelection,
  timeQuarterlyBlock,
  setTimeQuarterlyBlock
}) {
 const { apiRequest } = useUiContext();
  const [groups, setGroups] = useState([]);
  const [show, setShow] = useState(false);
  const [deviceListData, setDeviceListData] = useState(null);
  const [page, setpage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const timeZone = localStorage.getItem("tz");
  const [filterDates, setfilterDates] = useState({
    startDatetime: moment().tz(timeZone).startOf("day").toISOString(true),
    endDatetime: moment()
      .tz(timeZone)
      .add(1, "day")
      .startOf("day")
      .toISOString(true),
  });
  const [sort, setSort] = useState("");

  const changeState = () => {
    setShow((value) => !value);
  };
  useEffect(() => {
    const showData = localStorage.getItem("show");

    setShow(showData !== "false");
    if (localStorage.getItem("startDate") && localStorage.getItem("endDate")) {
      setfilterDates({
        startDatetime: moment
          .tz(localStorage.getItem("startDate"), timeZone)
          .toISOString(true),
        endDatetime: moment
          .tz(localStorage.getItem("endDate"), timeZone)
          .toISOString(true),
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("show", `${show}`);
  }, [show]);

  const getGroups = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", "api/1/groups/", "", headers, false)
      .then((res) => {
        if (res.data?.results !== null) {
          setGroups(res.data.results);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleScroll = () => {
    if (page <= totalPage) {
      setpage((prePage) => prePage + 1);
      if (page !== 1) {
        getDeviceList(page);
      }
    }
  };

  const getDeviceList = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest(
      "get",
      `api/1/devices/`,
      "",
      headers,
      true,
      { success: "", error: "" },
      { ordering: sort, page: page }
    )
      .then((res) => {
        if (res.data?.results !== null) {
          let data = res.data?.results;
          if (deviceListData === null) {
            setDeviceListData(data);
          } else {
            setDeviceListData((prevState) => {
              return [...prevState, ...data];
            });
          }
          setTotalPage(res.data.page_count);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getDeviceList().then((_) => {});
    getGroups().then((_) => {});
  }, [sort]);

  const resetDataHandler = () => {
    const startDatetime = moment()
      .tz(timeZone)
      .startOf("day")
      .toISOString(true);
    const endDatetime = moment()
      .tz(timeZone)
      .add(1, "day")
      .startOf("day")
      .toISOString(true);
    setFilter({
      start_time: "",
      device_uuid: "",
      group_uuid: "",
      startDatetime: startDatetime,
      endDatetime: endDatetime,
    });
    setfilterDates({ startDatetime: startDatetime, endDatetime: endDatetime });
    setSort("");
    setShow(false);
    setSelectedTimeframe("");
    // resetTimelineSelection();
    localStorage.removeItem("endDate");
    localStorage.removeItem("startDate");
    localStorage.removeItem("show");
    localStorage.removeItem("filter");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("groupUuid");
  };

  

     useEffect(() => {
    let data = localStorage.getItem('filter');
    data = JSON.parse(data);
  
    if (data && data.device_uuid) {
    setFilter({...filter,device_uuid:data.device_uuid})
    }
  }, []);  

  const checkedHandler = (id) => {
    setTimeQuarterlyBlock([])
    let subscriptionId = filter.device_uuid || [];
    const indexOfKey = subscriptionId.indexOf(id);
    if (indexOfKey === -1) {
      subscriptionId.push(id);
    } else {
      subscriptionId.splice(indexOfKey, 1);
    }
    const updatedFilter = { ...filter, device_uuid: subscriptionId };
    localStorage.setItem("filter", JSON.stringify(updatedFilter));
    setFilter(updatedFilter);
  };

  const setFilterDates = () => {
    if (filterDates.startDatetime !== "" && filterDates.endDatetime !== "") {
      setFilter({
        ...filter,
        startDatetime: filterDates.startDatetime,
        endDatetime: filterDates.endDatetime,
      });
    }
  };
  const handleCallback = (startDate, endDate) => {
    setFilter({
      ...filter,
      startDatetime: moment.tz(startDate._d, timeZone).toISOString(true),
      endDatetime: moment.tz(endDate._d, timeZone).toISOString(true),
    });
    localStorage.setItem(
      "startDate",
      moment.tz(startDate._d, timeZone).toISOString(true)
    );
    localStorage.setItem(
      "endDate",
      moment.tz(endDate._d, timeZone).toISOString(true)
    );
  };

  return (
    <div className={enable ? "sidebar-theme" : "sidebar-theme active"}>
      <div className="camera-header d-flex align-items-center">
        <div className="flex-grow-1 available-heading">
          <h3 className="d-none d-lg-block">Filters</h3>
          <h3 className="d-lg-none">Filters</h3>
        </div>
      </div>
      <div className="sidebar-wrapper">
        <div className="reset-wrapper d-flex justify-content-between">
          <button
            className="btn p-0 btn-link text-danger"
            onClick={resetDataHandler}
          >
            Reset all
          </button>
          {/* <RefreshButton /> */}
          <div className="d-lg-none">
            <button
              className="btn btn-sidebar-toggle p-0"
              type="button"
              onClick={() => setEnable(true)}
            >
              <Svg name="filter-icon" style="color:white;" />
            </button>
          </div>
          <div className="dropdown d-none d-lg-flex">
            <button
              className="btn"
              type="button"
              id="dropdownSorting"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Svg name="dropdown-icon" style="color:white;" />
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownSorting">
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => setSort("start_time")}
                >
                  By Date
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={() => setSort("name")}>
                  By Name
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="all-cameras-wrapper accordion d-lg-none">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#allSorting"
            aria-expanded="true"
            aria-controls="allCameras"
          >
            Sort
          </button>
          <div
            id="allSorting"
            className="accordion-collapse collapse"
            aria-labelledby="heading1"
          >
            <div className="accordion-body">
              <ul className="accordion-menu">
                <li>
                  <span
                    className="dropdown-item"
                    onClick={() => setSort("start_time")}
                  >
                    By Date
                  </span>
                </li>
                <li>
                  <span
                    className="dropdown-item"
                    onClick={() => setSort("name")}
                  >
                    By Name
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="all-cameras-wrapper accordion">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#allCameras"
            aria-expanded="true"
            aria-controls="allCameras"
          >
            Show all cameras
          </button>
          <div
            id="allCameras"
            className={`accordion-collapse collapse show`}
            aria-labelledby="heading1"
          >
            <div
              className="accordion-body"
              onScroll={handleScroll}
              style={{ overflowY: "scroll", height: "600px" }}
            >
              {deviceListData?.map((device, index) => (
                <div key={index} className="check-list">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        !!filter.device_uuid.includes(device.device_uuid)
                      }
                      onChange={(e) => checkedHandler(device.device_uuid)}
                      id={`camera${index}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`camera${index}`}
                    >
                      {device.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div className="date-wrapper">
                    <h3>15 SEP 2022</h3>
                    <p>Available Recordings</p>
                </div> */}
        <div className="time-frame">
          <div className="header d-flex align-items-center">
            <h4 className="flex-grow-1">Time frame</h4>
            <label className="switch">
              <input
                type="checkbox"
                checked={show}
                onChange={changeState}
                id="timeframe-toggle"
              />
              <span className="slider round"></span>
            </label>
          </div>
          {show ? (
            <>
              <div className="date-time-picker d-none">
                <div className="input-wrapper start-date">
                  <h4>Start date</h4>
                  <Datetime
                    onChange={(e) =>
                      setfilterDates({
                        ...filterDates,
                        startDatetime: moment
                          .tz(e._d, timeZone)
                          .toISOString(true),
                      })
                    }
                    value={filterDates.startDatetime}
                    useRef="startDatetime"
                    // dateFormat="DD-MM-YYYY"
                  />
                </div>
                <div className="input-wrapper end-date">
                  <h4>End date</h4>
                  <Datetime
                    onChange={(e) =>
                      setfilterDates({
                        ...filterDates,
                        endDatetime: moment
                          .tz(e._d, timeZone)
                          .toISOString(true),
                      })
                    }
                    value={filterDates.endDatetime}
                    useRef="endDatetime"
                    // dateFormat="DD-MM-YYYY"
                  />
                </div>
                <div className="apply-btn">
                  <button
                    className="btn btn-primary w-100 btn-rounded"
                    onClick={() => setFilterDates()}
                  >
                    Apply
                  </button>
                </div>
              </div>

              <div className="date-time-picker">
                <DateRangePicker
                  initialSettings={{
                    timePicker: true,
                    // showDropdowns: true,
                    // startDate: start.toDate(),
                    // endDate: end.toDate(),
                    locale: {
                      format: "DD/MM/YYYY hh:mm a",
                    },
                  }}
                  onCallback={handleCallback}
                >
                  {/* <input type="text" className="form-control" /> */}
                  <p className="form-control" style={{ textAlign: "center" }}>
                    {
                      ("label",
                      (filter.startDatetime
                        ? moment(filter.startDatetime)
                        : moment()
                      )
                        .tz(timeZone)
                        .startOf("day")
                        .format("DD/MM/YYYY HH:mm a"))
                    }
                    <br /> To
                    <br />
                    {
                      ("label",
                      (filter.endDatetime
                        ? moment(filter.endDatetime)
                        : moment()
                      )
                        .tz(timeZone)
                        .startOf("day")
                        .format("DD/MM/YYYY HH:mm a"))
                    }
                  </p>
                </DateRangePicker>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <fieldset className="group-checkbox">
          <legend className="col-form-label mt-0">Groups</legend>
          <div className="d-flex flex-wrap group-checkbox-wrap align-items-center">
            {groups?.map((group, index) => (
              <div
                key={index}
                className="form-check"
                onClick={() =>
                  setFilter({ ...filter, group_uuid: group.group_uuid })
                }
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="gridRadios"
                  onChange={(e) => e.target.checked}
                  checked={filter.group_uuid === group.group_uuid}
                  id={group.group_uuid}
                  defaultValue={group.group_uuid}
                />
                <label className="form-check-label" htmlFor={group.group_uuid}>
                  {group.name}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        {/* <div className="apply-btn py-3 text-center d-lg-none">
                    <button className='btn btn-primary btn-rounded'>Apply</button>
                </div> */}
      </div>
    </div>
  );
}
