import React, { useState, useMemo, useEffect } from 'react'
import { NavLink, redirect, useParams } from 'react-router-dom'
import GroupSidebar from '../../common/GroupSidebar'
import HeaderDashboard from '../../common/HeaderDashboard'
import Svg from '../../common/Svg'
import { baseUrl } from '../../../constants/common'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { useUiContext } from '../../../contexts/UiContext'
import { getLocal, checkUserRoutePermission } from '../../../helper/common'

export default function Details({ route }) {
    let role = getLocal("role")
    const [group, setGroup] = useState(null);
    const [addDevice, setAddDevice] = useState(null)
    const [removeDevice, setRemoveDevice] = useState()
    const [checkedDevice, setCheckedDevice] = useState([])
    const [enable, setEnable] = useState(true)
    const [createGroup, setCreateGroup] = useState("")
    const [groupUuid, setGroupUuid] = useState("");
    const { group_uuid } = useParams();
    const [groupList, setGroupList] = useState({groups: [], checkedList: {}});
    const { apiRequest, noOfColumnsGroupCameras,searchFilter } = useUiContext();
    const noForBootstrapClass = 12 / noOfColumnsGroupCameras;
    const navigate = useNavigate();
    const [authToken, setAuthToken] = useState('');
    
    // Get group device list
    const getDevicesInGroup = async () => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt")
        }
        let res = await apiRequest("get", `api/1/groups/${group_uuid}/`, {}, headers, true);
        let response = res.data;
        if(response?.devices?.length > 0){
            let devices = response.devices;
            try {
                for(let i = 0; i < devices.length > 0; i++) {
                    if(devices[i].is_active){
                        let res = await apiRequest('get', `api/1/devices/${devices[i].device_uuid}/mjpg_img_src/`, {}, headers, true);
                        if(res){
                            let response = res.response.data;
                            devices[i].live_url = response;
                        }
                    }
                }
                response.devices = devices;
            } catch (error) {
                console.log('error: ',error)
            }
        }
        setGroup(response);
    }
    useEffect(() => {
        getDevicesInGroup()
    }, [enable])

    useMemo(() => {
    }, [checkedDevice])

    // API for Add Device in the group
    const adddevice = (device_uuid) => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt")
        }
        let res = apiRequest("post", `api/1/groups/${group_uuid}/add_device_to/`, { device_uuid: device_uuid }, headers, true, { success: 'Camera has been added', error: 'Failed to add camera' });
        getDevicesInGroup()
        navigate('/customer/groups');
    }

    const handleAddDevice = () => {
        for (let i = 0; i < checkedDevice.length; i++) {
            adddevice(checkedDevice[i]);
        }
        setEnable(true);

    }

    const getTokens = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        const res = await apiRequest("get", `api_auth/1/auth_token`, {}, headers, false);
        setAuthToken(res.data);
    };
    useEffect(() => {
        getTokens().then(_ => { });
        return () => {
            window.stop();
        }
    }, []);

    const groupsList = async () => {
        let header = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        let params = {};
        if (groupUuid) params.group_uuid = groupUuid;
        if (searchFilter) params.name__contains = searchFilter;
        return await apiRequest("get", 'api/1/groups/', {}, header, true, {success: '', error: ''}, params).then(res => {
            let checkedList = {}
            res.data.results.map((el) => {
                checkedList[el.group_uuid] = false;
                return el
            })
            setGroupList({...groupList, groups: res.data.results, checkedList: checkedList})
        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        groupsList().then(_ => {});
    }, [groupUuid, searchFilter])



    // API for remove Device from the group
    const removeGroupDevice = ((device_uuid) => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt")
        }

        let res = apiRequest("post", `api/1/groups/${group_uuid}/remove_device_from/`, { device_uuid }, headers, true, { success: 'Camera has been removed', error: 'Failed to removed camera' });
        if (res) {
            let devices = group.devices;
            group.devices.map(async (el, key) => {
                if (el.device_uuid === device_uuid) {
                    await devices.splice(key, 1);
                }
            });
            setGroup({ ...group, devices: devices })
        }
    })
    const deleteHandler = (device_uuid) => {
        removeGroupDevice(device_uuid)
    }
    const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;

    const openFullscreen = (imageId) => {
        document
        .getElementById(imageId)
        ?.requestFullscreen()
        .then((_) => {});
    }


    // Create group API
    const createHandler = (e) => {
        e.preventDefault()
        newGroup(createGroup).then(_ => {
        });
    }

    const newGroup = async (createGroup) => {
        let header = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        let uis = localStorage.getItem("customeruuid")
        return await apiRequest("post", 'api/1/groups/', {customer_uuid: uis, name: createGroup}, header, true,
            {success: 'Group has been created', error: 'Failed to create group',showToast:true}).then(_ => groupsList());
    }

    return (
        <>

            <div className='d-flex flex-column site-main-wrapper grid-page'>
                <HeaderDashboard route={route} />
                <div className="flex-grow-1 main-wrapper">

                    {/* listing page */}

                    <div className="main-page-height devices-page d-flex">
                        <GroupSidebar enable={enable} setCheckedDevice={setCheckedDevice} handleAddDevice={handleAddDevice} setEnable={setEnable} route={route} />
                        <div className="flex-grow-1">
                            <div className="d-flex flex-column">
                                {isAdmin ? <div className="camera-header d-flex align-items-center details-header">
                                    <div className="flex-grow-1 available-heading ps-0">
                                        <h2>Create New Group<button className='add-btn' data-bs-toggle="modal" data-bs-target="#addModal"><Svg name="add-gropup-note" /></button></h2>
                                    </div>

                                </div> : null}
                                <div className="flex-grow-1 camera-list-wrapper">
                                    <div className="groups-list-wrapper accordion" id="accordionGroups">
                                        <div className="row">

                                            <div className="col-lg-12">

                                                <div className="accordion-item">

                                                    <button className="accordion-button no-icon" type="button" >
                                                        {group?.name}
                                                    </button>
                                                    <div className="accordion-collapse collapse show" aria-labelledby="heading1"
                                                        data-bs-parent="#accordionGroups">
                                                        <div className="mt-4 camera-row row">

                                                            {group?.devices.length == 0 ?
                                                                <div className="alert alert-primary" style={{ textAlign: "center" }}>Empty Group</div>
                                                                : group?.devices?.map((device, deviceIndex) => (
                                                                    <div className={`col-lg-${noForBootstrapClass} mb-4`} key={deviceIndex}>
                                                                        <div className="card-camera" key={device.device_uuid}>
                                                                            <div className="card-header d-flex">
                                                                                <div className="camera-status">
                                                                                    <Svg name="camera-active" />
                                                                                </div>
                                                                                <div className="camera-details flex-grow-1 d-flex align-items-center">
                                                                                    <div className="flex-grow-1 camera-name">{device.name} </div>
                                                                                    {isAdmin ? checkUserRoutePermission(role, `/${route}/cameras/details/:device_uuid`) && <div className="button-wrap">
                                                                                        <Link to={`/${route}/cameras/details/${device.device_uuid}`} className='setting-icons'>
                                                                                            <Svg name="setting" />
                                                                                        </Link>
                                                                                        <span></span>
                                                                                    </div> : null}
                                                                                    {isAdmin ? <div className="delete-button-wraps">
                                                                                        <button type='button' className='setting-icons' onClick={() => deleteHandler(device.device_uuid)}>
                                                                                            <Svg name="delete-cross" />
                                                                                        </button>
                                                                                    </div> : null}
                                                                                </div>
                                                                            </div>
                                                                            {checkUserRoutePermission(role, `/${route}/cameras/live-camera/:device_uuid`) && 
                                                                                device.is_active && device.status === 'ok' ? <>
                                                                               <Link
                                                                                  to={`/${route}/cameras/live-camera/${device.device_uuid}`}
                                                                                  >
                                                                                    {device.is_subscribed ? (
                                                                                      <div className="card-camera-body">
                                                                                        <img
                                                                                          id={`image-${device.device_uuid}`}
                                                                                          src={device.live_url}
                                                                                          className="img-fluid"
                                                                                        />
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div className="card-camera-body no-subscriptions novideo">
                                                                                        <div className="bg-danger text-white alert alert-primary">
                                                                                          Offline:{" "}
                                                                                          {
                                                                                            device.live_url.erro.device_uuid
                                                                                          }
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                          </Link>
                                                                                </>
                                                                                    : null
                                                                                }
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            <>
                                                                {/* {enable ?
                                                                    <div className="button-wrapper">
                                                                        <button className='btn btn-primary btn-rounded' onClick={() => setEnable(false)}>Add Camera <Svg name="add-btn" /></button>
                                                                    </div>
                                                                    :
                                                                    <div className="button-wrapper d-flex">
                                                                        <div className="save-button">
                                                                            <button className='btn btn-primary btn-rounded' onClick={handleAddDevice}>Save</button>
                                                                        </div>
                                                                        <div className="cancel-button">
                                                                            <button className='btn btn-delete-new btn-rounded' onClick={() => setEnable(true)} >Cancel</button>
                                                                        </div>
                                                                    </div>
                                                                } */}
                                                            </>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade add-modal" id="addModal" tabIndex={-1} aria-labelledby="addModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">

                    <div className="modal-content">
                        {isAdmin ? <div className="modal-header">
                            <h5 className="modal-title text-center">Create new group</h5>
                            <button type="button" className='btn-close' data-bs-dismiss="modal" aria-label="Close">
                                <Svg name="close-rounded" />
                            </button>
                        </div> : null}
                        <div className="modal-body">
                            <div className="theme-form-wrapper">
                                <form>
                                    <div className="d-flex mb__20 align-items-center">
                                        <label htmlFor="groupName" className="col-form-label text-end">Group name</label>
                                        <div className="flex-grow-1">
                                            <input type="text"  onChange={(e) => setCreateGroup(e.target.value)} className="form-control" id="groupName" placeholder='Group name' />
                                        </div>
                                    </div>
                                    <div className="button-wrap text-center">
                                        <button className='btn btn-primary btn-rounded' type='button' data-bs-dismiss="modal" onClick={createHandler}>Create</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </div>



        </>
    )
}
